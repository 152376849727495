import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1eea9690 = () => interopDefault(import('../pages/book-demo.vue' /* webpackChunkName: "pages/localazy-meeting" */))
const _6431ac7e = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _029506fe = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _76f70f4a = () => interopDefault(import('../pages/ambassador/index.vue' /* webpackChunkName: "pages/ambassador/index" */))
const _cb774ffa = () => interopDefault(import('../pages/benefits.vue' /* webpackChunkName: "pages/benefits" */))
const _2ae9892c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _6271517c = () => interopDefault(import('../pages/build-version.vue' /* webpackChunkName: "pages/build-version" */))
const _2beee294 = () => interopDefault(import('../pages/case-study/index.vue' /* webpackChunkName: "pages/case-study/index" */))
const _10b15dcb = () => interopDefault(import('../pages/compare-plans.vue' /* webpackChunkName: "pages/compare-plans" */))
const _65851fae = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3ecdbcf0 = () => interopDefault(import('../pages/developers.vue' /* webpackChunkName: "pages/developers" */))
const _b6450c40 = () => interopDefault(import('../pages/dictionary/index.vue' /* webpackChunkName: "pages/dictionary/index" */))
const _5892d085 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _3acbc03c = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _7979e566 = () => interopDefault(import('../pages/integrations.vue' /* webpackChunkName: "pages/integrations" */))
const _ea1721ce = () => interopDefault(import('../pages/link-expired.vue' /* webpackChunkName: "pages/link-expired" */))
const _4f9c5b5a = () => interopDefault(import('../pages/localization-estimator.vue' /* webpackChunkName: "pages/localization-estimator" */))
const _43bdd19c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c44e5f26 = () => interopDefault(import('../pages/lost-password.vue' /* webpackChunkName: "pages/lost-password" */))
const _4e655415 = () => interopDefault(import('../pages/onboarding-survey.vue' /* webpackChunkName: "pages/onboarding-survey" */))
const _5a4f2c8d = () => interopDefault(import('../pages/partnership.vue' /* webpackChunkName: "pages/partnership" */))
const _c8fae78e = () => interopDefault(import('../pages/partnerships/index.vue' /* webpackChunkName: "pages/partnerships/index" */))
const _27aca8cf = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _3b9eb79e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _2b1b5c6a = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2887e640 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _5ff5af9b = () => interopDefault(import('../pages/sso/index.vue' /* webpackChunkName: "pages/sso/index" */))
const _7765ec24 = () => interopDefault(import('../pages/suspended.vue' /* webpackChunkName: "pages/suspended" */))
const _ebe8b63a = () => interopDefault(import('../pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _50fd803b = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _3b2bbd7b = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _29204d0f = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _33bf1d92 = () => interopDefault(import('../pages/leaderboards/top-contributors.vue' /* webpackChunkName: "pages/leaderboards/top-contributors" */))
const _5f9f89d8 = () => interopDefault(import('../pages/leaderboards/top-projects.vue' /* webpackChunkName: "pages/leaderboards/top-projects" */))
const _353b8306 = () => interopDefault(import('../pages/sso/errors/general-error.vue' /* webpackChunkName: "pages/sso/errors/general-error" */))
const _75111fc8 = () => interopDefault(import('../pages/sso/errors/invalid-idp-response.vue' /* webpackChunkName: "pages/sso/errors/invalid-idp-response" */))
const _7048086b = () => interopDefault(import('../pages/sso/errors/no-sso-configured.vue' /* webpackChunkName: "pages/sso/errors/no-sso-configured" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _eaf77d10 = () => interopDefault(import('../pages/ambassador/action/_slug.vue' /* webpackChunkName: "pages/ambassador/action/_slug" */))
const _9ebfdec8 = () => interopDefault(import('../pages/blog/author/_slug.vue' /* webpackChunkName: "pages/blog/author/_slug" */))
const _66401ad7 = () => interopDefault(import('../pages/leaderboards/projects/_slug.vue' /* webpackChunkName: "pages/leaderboards/projects/_slug" */))
const _f8714516 = () => interopDefault(import('../pages/addons/_slug.vue' /* webpackChunkName: "pages/addons/_slug" */))
const _2932e7e4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c9b3ad1e = () => interopDefault(import('../pages/case-study/_slug/index.vue' /* webpackChunkName: "pages/case-study/_slug/index" */))
const _257d0854 = () => interopDefault(import('../pages/community/_section/index.vue' /* webpackChunkName: "pages/community/_section/index" */))
const _521cd094 = () => interopDefault(import('../pages/convert/_from_to.vue' /* webpackChunkName: "pages/convert/_from_to" */))
const _25b6e5ca = () => interopDefault(import('../pages/dictionary/_slug/index.vue' /* webpackChunkName: "pages/dictionary/_slug/index" */))
const _4626b586 = () => interopDefault(import('../pages/docs/_section/index.vue' /* webpackChunkName: "pages/docs/_section/index" */))
const _46c1225d = () => interopDefault(import('../pages/embed/_slug.vue' /* webpackChunkName: "pages/embed/_slug" */))
const _25dcccb0 = () => interopDefault(import('../pages/faq/_category/index.vue' /* webpackChunkName: "pages/faq/_category/index" */))
const _8d527182 = () => interopDefault(import('../pages/features/_slug.vue' /* webpackChunkName: "pages/features/_slug" */))
const _3d0186e6 = () => interopDefault(import('../pages/for/_slug.vue' /* webpackChunkName: "pages/for/_slug" */))
const _3b071337 = () => interopDefault(import('../pages/p/_slug/index.vue' /* webpackChunkName: "pages/p/_slug/index" */))
const _283eb8b4 = () => interopDefault(import('../pages/partnerships/_slug/index.vue' /* webpackChunkName: "pages/partnerships/_slug/index" */))
const _027929de = () => interopDefault(import('../pages/tags/_slug/index.vue' /* webpackChunkName: "pages/tags/_slug/index" */))
const _19a7082e = () => interopDefault(import('../pages/term/_slug.vue' /* webpackChunkName: "pages/term/_slug" */))
const _27cbaf56 = () => interopDefault(import('../pages/tiers/_slug.vue' /* webpackChunkName: "pages/tiers/_slug" */))
const _12894119 = () => interopDefault(import('../pages/translate/_from_to.vue' /* webpackChunkName: "pages/translate/_from_to" */))
const _72dff8d4 = () => interopDefault(import('../pages/zapier-integration/_slug.vue' /* webpackChunkName: "pages/zapier-integration/_slug" */))
const _5f5d3023 = () => interopDefault(import('../pages/p/_slug/contributors.vue' /* webpackChunkName: "pages/p/_slug/contributors" */))
const _1600be91 = () => interopDefault(import('../pages/community/_section/_slug/index.vue' /* webpackChunkName: "pages/community/_section/_slug/index" */))
const _109ff441 = () => interopDefault(import('../pages/docs/_section/_slug/index.vue' /* webpackChunkName: "pages/docs/_section/_slug/index" */))
const _24262b68 = () => interopDefault(import('../pages/faq/_category/_slug.vue' /* webpackChunkName: "pages/faq/_category/_slug" */))
const _12c2d2d3 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/localazy-meeting",
    component: _1eea9690,
    name: "localazy-meeting"
  }, {
    path: "/404",
    component: _6431ac7e,
    name: "404"
  }, {
    path: "/500",
    component: _029506fe,
    name: "500"
  }, {
    path: "/ambassador",
    component: _76f70f4a,
    name: "ambassador"
  }, {
    path: "/benefits",
    component: _cb774ffa,
    name: "benefits"
  }, {
    path: "/blog",
    component: _2ae9892c,
    name: "blog"
  }, {
    path: "/book-demo",
    component: _1eea9690,
    name: "book-demo"
  }, {
    path: "/build-version",
    component: _6271517c,
    name: "build-version"
  }, {
    path: "/case-study",
    component: _2beee294,
    name: "case-study"
  }, {
    path: "/compare-plans",
    component: _10b15dcb,
    name: "compare-plans"
  }, {
    path: "/contact",
    component: _65851fae,
    name: "contact"
  }, {
    path: "/developers",
    component: _3ecdbcf0,
    name: "developers"
  }, {
    path: "/dictionary",
    component: _b6450c40,
    name: "dictionary"
  }, {
    path: "/docs",
    component: _5892d085,
    name: "docs"
  }, {
    path: "/faq",
    component: _3acbc03c,
    name: "faq"
  }, {
    path: "/integrations",
    component: _7979e566,
    name: "integrations"
  }, {
    path: "/link-expired",
    component: _ea1721ce,
    name: "link-expired"
  }, {
    path: "/localization-estimator",
    component: _4f9c5b5a,
    name: "localization-estimator"
  }, {
    path: "/login",
    component: _43bdd19c,
    name: "login"
  }, {
    path: "/lost-password",
    component: _c44e5f26,
    name: "lost-password"
  }, {
    path: "/onboarding-survey",
    component: _4e655415,
    name: "onboarding-survey"
  }, {
    path: "/partnership",
    component: _5a4f2c8d,
    name: "partnership"
  }, {
    path: "/partnerships",
    component: _c8fae78e,
    name: "partnerships"
  }, {
    path: "/pricing",
    component: _27aca8cf,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _3b9eb79e,
    name: "privacy-policy"
  }, {
    path: "/register",
    component: _2b1b5c6a,
    name: "register"
  }, {
    path: "/reset-password",
    component: _2887e640,
    name: "reset-password"
  }, {
    path: "/sso",
    component: _5ff5af9b,
    name: "sso"
  }, {
    path: "/suspended",
    component: _7765ec24,
    name: "suspended"
  }, {
    path: "/tags",
    component: _ebe8b63a,
    name: "tags"
  }, {
    path: "/terms-and-conditions",
    component: _50fd803b,
    name: "terms-and-conditions"
  }, {
    path: "/testimonials",
    component: _3b2bbd7b,
    name: "testimonials"
  }, {
    path: "/verify-email",
    component: _29204d0f,
    name: "verify-email"
  }, {
    path: "/leaderboards/top-contributors",
    component: _33bf1d92,
    name: "leaderboards-top-contributors"
  }, {
    path: "/leaderboards/top-projects",
    component: _5f9f89d8,
    name: "leaderboards-top-projects"
  }, {
    path: "/sso/errors/general-error",
    component: _353b8306,
    name: "sso-errors-general-error"
  }, {
    path: "/sso/errors/invalid-idp-response",
    component: _75111fc8,
    name: "sso-errors-invalid-idp-response"
  }, {
    path: "/sso/errors/no-sso-configured",
    component: _7048086b,
    name: "sso-errors-no-sso-configured"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/ambassador/action/:slug?",
    component: _eaf77d10,
    name: "ambassador-action-slug"
  }, {
    path: "/blog/author/:slug?",
    component: _9ebfdec8,
    name: "blog-author-slug"
  }, {
    path: "/leaderboards/projects/:slug?",
    component: _66401ad7,
    name: "leaderboards-projects-slug"
  }, {
    path: "/addons/:slug?",
    component: _f8714516,
    name: "addons-slug"
  }, {
    path: "/blog/:slug",
    component: _2932e7e4,
    name: "blog-slug"
  }, {
    path: "/case-study/:slug",
    component: _c9b3ad1e,
    name: "case-study-slug"
  }, {
    path: "/community/:section",
    component: _257d0854,
    name: "community-section"
  }, {
    path: "/convert/:from_to?",
    component: _521cd094,
    name: "convert-from_to"
  }, {
    path: "/dictionary/:slug",
    component: _25b6e5ca,
    name: "dictionary-slug"
  }, {
    path: "/docs/:section",
    component: _4626b586,
    name: "docs-section"
  }, {
    path: "/embed/:slug?",
    component: _46c1225d,
    name: "embed-slug"
  }, {
    path: "/faq/:category",
    component: _25dcccb0,
    name: "faq-category"
  }, {
    path: "/features/:slug?",
    component: _8d527182,
    name: "features-slug"
  }, {
    path: "/for/:slug?",
    component: _3d0186e6,
    name: "for-slug"
  }, {
    path: "/p/:slug",
    component: _3b071337,
    name: "p-slug"
  }, {
    path: "/partnerships/:slug",
    component: _283eb8b4,
    name: "partnerships-slug"
  }, {
    path: "/tags/:slug",
    component: _027929de,
    name: "tags-slug"
  }, {
    path: "/term/:slug?",
    component: _19a7082e,
    name: "term-slug"
  }, {
    path: "/tiers/:slug?",
    component: _27cbaf56,
    name: "tiers-slug"
  }, {
    path: "/translate/:from_to?",
    component: _12894119,
    name: "translate-from_to"
  }, {
    path: "/zapier-integration/:slug?",
    component: _72dff8d4,
    name: "zapier-integration-slug"
  }, {
    path: "/p/:slug?/contributors",
    component: _5f5d3023,
    name: "p-slug-contributors"
  }, {
    path: "/community/:section?/:slug",
    component: _1600be91,
    name: "community-section-slug"
  }, {
    path: "/docs/:section/:slug",
    component: _109ff441,
    name: "docs-section-slug"
  }, {
    path: "/faq/:category/:slug",
    component: _24262b68,
    name: "faq-category-slug"
  }, {
    path: "/:slug",
    component: _12c2d2d3,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
